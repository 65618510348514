<template>
<v-dialog v-model="dialog" width="700">
  <v-toolbar height="40" dark dense color="primary">
    <div v-if="type=='daily'" class="text-h6">提醒下属写日报</div>
    <div v-if="type=='weekly'" class="text-h6">提醒下属写周报</div>
    <div v-if="type=='monthly'" class="text-h6">提醒下属写月报</div>
    <v-spacer></v-spacer>
    <v-icon @click="dialog=false">mdi-close</v-icon>
  </v-toolbar>
  <v-card style="overflow:auto;" width="700" height="400">
    <v-container fluid>
      <div class="px-5 d-flex align-center">
        <div class="text-subtitle-2 grey--text mr-2"><span class="red--text">*</span> 提醒对象</div>
        <div class="d-flex">
          <v-checkbox v-for="(user, i) in showUsers" :key="i" v-model="users" color="blue" :value="user">
            <template v-slot:label>
              <UserAvatar class="ml-n2 mr-2" :name="user.employeeName.charAt(0)" width='30px' height='30px' :userId="user.id" />
            </template>
          </v-checkbox>
        </div>
      </div>
      <div style="heigth:80px;" height="80" class="pa-5 align-start d-flex my-0">
        <div class="text-subtitle-2 grey--text mr-2"><span class="red--text">*</span> 提醒内容</div>
        <v-textarea class="mt-0" rows="2" v-model="text"></v-textarea>
      </div>
      <div class="mt-0 pl-12">
        <v-checkbox :disabled="i==0" class="my-0" v-for="(text ,i) in modeText" :key="i" v-model="mode" color="blue" :value="i">
          <template v-slot:label>
            <div class="text-subtitle-2 grey--text">{{ text }}</div>
          </template>
        </v-checkbox>
      </div>
      <div class="d-flex mt-n3">
        <v-spacer></v-spacer>
        <v-btn @click="dialog=false" class="mx-1" small>取消</v-btn>
        <v-btn @click="agree" class="mx-1" small color="primary">确定</v-btn>
      </div>
    </v-container>
  </v-card>
</v-dialog>
</template>
<script>
export default {
  name: 'SubNotificationDlg',
  components: {
    UserAvatar: () => import('@/components/common/UserAvatar.vue'),
  },
  data: () => ({
    dialog: false,
    users: [],
    showUsers: [],
    text: '',
    modeText: ['系统通知（内容为系统自动生成）', '附带统计表', '同时抄送我自己'],
    mode: [0, 1, 2],
    type: null,
  }),
  methods: {
    open(props) {
      this.dialog = true;
      this.users = props.users;
      this.showUsers = this.users;
      this.text = '请在今天19:00前及时交付今天的日报。';
      this.type = props.type;
    },
    agree() {
      this.$emit('agree', {
        users: this.users,
        mode: this.mode
      });
      this.dialog = false;
    }
  }
}
</script>
<style scoped>
.v-textarea textarea{
  height: 50px;
}
</style>